// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-bewertungen-js": () => import("./../../../src/pages/bewertungen.js" /* webpackChunkName: "component---src-pages-bewertungen-js" */),
  "component---src-pages-datenschutz-js": () => import("./../../../src/pages/datenschutz.js" /* webpackChunkName: "component---src-pages-datenschutz-js" */),
  "component---src-pages-ernaehrungsberatung-js": () => import("./../../../src/pages/ernaehrungsberatung.js" /* webpackChunkName: "component---src-pages-ernaehrungsberatung-js" */),
  "component---src-pages-impressum-js": () => import("./../../../src/pages/impressum.js" /* webpackChunkName: "component---src-pages-impressum-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-kontakt-js": () => import("./../../../src/pages/kontakt.js" /* webpackChunkName: "component---src-pages-kontakt-js" */),
  "component---src-pages-neuro-athletik-js": () => import("./../../../src/pages/neuro-athletik.js" /* webpackChunkName: "component---src-pages-neuro-athletik-js" */),
  "component---src-pages-personal-training-js": () => import("./../../../src/pages/personal-training.js" /* webpackChunkName: "component---src-pages-personal-training-js" */),
  "component---src-pages-schmerztherapie-js": () => import("./../../../src/pages/schmerztherapie.js" /* webpackChunkName: "component---src-pages-schmerztherapie-js" */),
  "component---src-pages-seminare-js": () => import("./../../../src/pages/seminare.js" /* webpackChunkName: "component---src-pages-seminare-js" */),
  "component---src-pages-ueber-mich-js": () => import("./../../../src/pages/ueber-mich.js" /* webpackChunkName: "component---src-pages-ueber-mich-js" */)
}

